<template>
  <template v-for="aF in dataValue.functions">
    <view class="aRow">
      <view :class="allEvent.aNode(aF)" @click="allEvent.setCheck.now(aF)">
        <check-outlined style="color:red;" v-if="aF['haveCheck'] === 1"/>
        <border-outlined v-if="aF['haveCheck'] === 0" style="color: #cccccc;"/>
        {{ aF['functionName'] }} <text>{{ aF['functionId'] }}</text>
      </view>
      <view class="childRow" v-if="aF['functions'].length > 0">
        <function_tree :functions="aF['functions']" @parent_set_fun="allEvent.setCheck.parent"/>
      </view>
    </view>
  </template>

</template>

<script>
import {reactive, watch} from "vue";

export default {
  name : "function_tree",
  props : [ 'functions', 'top_fun' ],
  emits : [ 'parent_set_fun' ],
  setup(props, { emit }) {

    let dataValue = reactive({
      functions : []
    });
    dataValue.functions = props.functions;

    watch(() => props, (newProps) => {
      dataValue.functions = newProps.functions;
    }, { deep : true, immediate : true });


    let allEvent = {

      aNode :(aRow) =>{
        if( aRow['parentFunctionId'] === 0  ) return "aNode topNode"
        else return "aNode"
      },

      setCheck : {

        now : (aF) => {
          if (aF['haveCheck'] === 1) {
            aF['haveCheck'] = 0;
            allEvent.setCheck.child(aF);
          } else {
            aF['haveCheck'] = 1;
            allEvent.setCheck.parent(aF)
          }
        },

        child : (aF) => {
          aF['functions'].forEach((aCF) => {
            aCF['haveCheck'] = 0;
            allEvent.setCheck.child(aCF);
          });
        },

        parent : (aF) => {
          emit('parent_set_fun', aF);
        }

      },


    }

    return {
      dataValue, allEvent
    }
  }
}
</script>

<style scoped>
.aRow { float: left; clear: both; line-height: 28px; cursor: pointer; }
.aRow text{ color: #dddeee; margin-left: 10px }
.aNode { float: left; clear: both; }
.aNode:hover {color: red;}
.childRow { float: left; clear: both; margin-left: 31px; }
.topNode{ color: #1a5f91; margin-top: 15px; }
</style>