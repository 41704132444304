<template>
  <view class="pEmp">
    <template v-for="aEmployee in dataValue.employeeList.list">
      <view :class="allEvent.aEmployeeColor(aEmployee)" @click="allEvent.selectEmployee(aEmployee)"
            v-if="aEmployee['haveAdmin'] !== 1" >
        <user-outlined/>
        {{ aEmployee["employeeName"] }}
        <right-outlined class="toRight"/>
      </view>
    </template>
  </view>
  <view class="pList">
    <a-spin class="loadingClass" v-if="dataValue.employeeFunLoadingText !== ''" :tip="dataValue.employeeFunLoadingText"></a-spin>
    <funTree v-if="dataValue.employeeFunLoadingText === ''"
             :functions="dataValue.employeeFun['functions']"
             @parent_set_fun="allEvent.setParentCheckNode"
    />
  </view>
  <view class="saveButton" v-if="dataValue.employeeFunLoadingText === ''">
    <a-button type="primary" @click="allHttp.saveFunc">保存设置</a-button>
  </view>
</template>

<script>
import {reactive} from "vue";
import httpRequest from "@/utils/axios";
import {message} from "ant-design-vue";
import funTree from "@/components/function_tree";

export default {
  name : "function_set",
  emits : [ 'setMenuKey' ],
  components : { funTree },
  setup(props, { emit }) {
    emit('setMenuKey', 'set.qxsz');

    let dataValue = reactive({
      clickEmployeeId : 0,
      employeeList : {
        allCount : 0,
        list : []
      },
      employeeFunLoadingText : "",
      employeeFun : []
    });

    let allHttp = {

      getEmployee : () => {

        httpRequest.post('/admin/eic/employee.list', {}).then((res) => {
          if (res.code !== 200) {
            message.error(res.message);
          } else {
            dataValue.employeeList.allCount = res["data"]["allCount"];
            dataValue.employeeList.list = res["data"]["list"];


            if (dataValue.employeeList.list.length > 0) {
              dataValue.clickEmployeeId = dataValue.employeeList.list[0]['employeeId'];
              allHttp.getEmployeeFun();
            }
          }

        })
      },

      getEmployeeFun : () => {

        dataValue.employeeFunLoadingText = '正在加载数据 ... ...';
        httpRequest.post('/admin/set/function/employee.fun', { "employeeId" : dataValue.clickEmployeeId }).then((res) => {
          if (res.code !== 200) {
            message.error(res.message);
          } else {
            dataValue.employeeFun = res["data"];
            dataValue.employeeFunLoadingText = '';
          }

        })

      },

      saveFunc :()=>{

        let inputData = {
          employeeId : dataValue.clickEmployeeId,
          functions : []
        }

        // 获得选中的记录
        let getCheckFunc = ( funList )=>{
          funList.forEach((v)=>{
            if( v['haveCheck'] === 1 ) inputData.functions.push(v['functionId']);
            getCheckFunc( v['functions'] );
          });
        }
        getCheckFunc( dataValue.employeeFun.functions )


        // 执行保存操作
        dataValue.employeeFunLoadingText = '正在保存数据 ... ...';
        httpRequest.post('/admin/set/function/modify', inputData).then((res) => {
          if (res.code !== 200) {
            message.error(res.message);
          } else {
            message.info('保存成功！');
            dataValue.employeeFunLoadingText = '';
          }

        })

      },

      init : () => {
        allHttp.getEmployee();
      }
    }
    allHttp.init();


    let allEvent = {

      aEmployeeColor : (aEmp) => {
        if (aEmp["employeeId"] === dataValue.clickEmployeeId) return "aEmp empColor";
        else return "aEmp";
      },

      selectEmployee : (aEmp) => {
        dataValue.clickEmployeeId = aEmp["employeeId"];
        allHttp.getEmployeeFun();
      },

      // 从子节点传递上来的，需要点击父节点
      setParentCheckNodeSync : (functionList, parentFunctionId) => {

        functionList.forEach((fList) => {

          if (fList['functionId'] === parentFunctionId) {
            fList['haveCheck'] = 1;
            if (fList['parentFunctionId'] !== 0) {
              allEvent.setParentCheckNodeSync(dataValue.employeeFun['functions'], fList['parentFunctionId']);
            }
          }

          allEvent.setParentCheckNodeSync(fList['functions'], parentFunctionId);

        })


      },
      setParentCheckNode : (aF) => {
        allEvent.setParentCheckNodeSync(dataValue.employeeFun['functions'], aF['parentFunctionId']);
      }

    }

    return {
      dataValue, allEvent, allHttp
    }
  }
}
</script>

<style scoped>
.pEmp { width: 140px; float: left; clear: both; margin-top: 10px;}
.aEmp { clear: both; float: left; padding: 9px 6px 9px 6px; margin-left: 20px; border-bottom: 1px solid #dddddd;
  width: 100%; cursor: pointer; }
.aEmp:hover { color: red; }
.aEmp .toRight { font-size: 10px; float: right; margin-top: 7px; color: #cccccc; }
.empColor { color: #1890ff; border-bottom: 1px solid #1890ff; background-color: #e6f7ff; }

.pList { position: fixed; top: 70px; left: 200px; border: 0; width:calc( 100% - 200px ); float: left; padding: 20px 20px 20px 60px;
    overflow: auto; height: calc( 100% - 80px ); background-color: #fefffe; }
.loadingClass { margin: 30px; }

.saveButton{ position: fixed; top:100px; left: 600px; }
</style>